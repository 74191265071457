import axios from "axios";
import DefaultClient from "./DefaultClient";
import { AddressType } from "../contexts/WalletContext";

/**
  등록된 NFT 리스트 조회
   * @param {object} params - 파라미터 객체
   * @param {number} params.page 현 페이지
   * @param {number} params.size 가져올 갯수
   * @param {any?} params.query 쿼리 내용
   * @param {string?} params.category 카테고리
   * @param {AddressType} params.address 지갑 주소
*/
const getList = async ({page, size, query, category, address}:{page: number, size: number, query?: any, category?: string, address?: AddressType}) => {
    const postData = {
        page: page,
        size: size,
        category: category,
        query: JSON.stringify(query ?? {}).replaceAll('\"true\"','true'),
    };
    const res = await DefaultClient.post('/api/nft', postData, {
        headers: {
            'wallet': address
        }
    });
    return res.data;
}

/**
  카테고리 정보 조회
   * @param {string} category 카테고리
*/
const getCategoryInfo = async (category: string) => {
    const postData = {
        category: category,
    };
    const res = await DefaultClient.post('/api/nft/category', postData);
    return res.data;
}

/**
  NFT 정보 가져오기
   * @param {string} pk NFT pk
   * @param {AddressType} address 지갑 주소
*/
const getInfo = async (
    pk: string,
    address: AddressType,
) => {
    const postData = {
        pk: pk
    };
    const res = await DefaultClient.post('/api/nft/getNftInfo', postData , {
        headers: {
            'wallet': address
        }
    });
    return res.data;
}

/**
  NFT 좋아요
   * @param {number} pk NFT pk
   * @param {string} address 지갑 주소
*/
const setLike = async (
    pk: number,
    address: string,
) => {
    const postData = {
        nftPk: pk,
        wallet: address
    };
    const res = await DefaultClient.put(`/api/nft/like`, postData);

    return res.data;
}

/**
  NFT 좋아요 가져오기
   * @param {object} params - 파라미터 객체
   * @param {number} params.page 현 페이지
   * @param {number} params.size 가져올 갯수
   * @param {string?} params.category 카테고리
   * @param {AddressType} params.address 지갑 주소
*/
const getLike = async (
    {page, size, query, address}:{page: number, size: number, query?: any, address: AddressType}
) => {
    const postData = {
        page: page,
        count: size,
        query: JSON.stringify(query ?? {}).replaceAll('\"true\"','true'),
    };
    const res = await DefaultClient.post(`/api/nft/like`, postData, {
        headers: {
            wallet: address
        }
    });

    return res.data;
}


/**
  NFT 거래
   * @param {string} network NFT 체인 네트워크 ex)ethereum
   * @param {string} chainId 내가 보낸 native 코인의 체인
   * @param {string} contract NFT 컨트렉트 주소
   * @param {string} tokenId NFT ID
   * @param {string} fromAddress 판매자 지갑
   * @param {string} toAddress 구매자 지갑
   * @param {string} price 가격
*/
const trade = async (
    network: string,
    chainId: number,
    contract: string,
    tokenId: string,
    fromAddress: string,
    toAddress: string,
    price: string,
) => {
    const postData = {
        chainId: chainId,
        network: network,
        contractAddress: contract,
        tokenId: tokenId,
        fromAddress: fromAddress,
        toAddress: toAddress,
        price: price,
    };
    const res = await DefaultClient.post('/api/nft/nftTrade', postData);
    return res.data;
}


/**
  NFT 거래 내역 가져오기
   * @param {number} contract NFT pk
   * @param {string} tokenId 지갑 주소
*/
const getTx = async (
    contract: string,
    tokenId: string,
) => {
    const postData = {
        nft_contract: contract,
        tokenId: tokenId
    };
    const res = await DefaultClient.post(`/api/nft/tx`, postData);

    return res.data;
}

/**
  코인 달러로 환산 (사용 X)
   * @param {string} symbol 심볼
   * @param {string} price 코인양
*/
const exchange = async (
    symbol: string,
    price: string,
) => {
    const postData = {
        symbol: symbol,
        price: price
    };
    const res = await DefaultClient.post(`/api/nft/exchange`, postData);

    return res.data;
}

/**
  코인 달러 환정 정보
*/
const exchangeToDollar = async (
) => {
    const res = await DefaultClient.get(`/api/nft/exchange/data`);

    return res.data;
}


/**
  NFT approve후 실행 함수
   * @param {string} pk pk
   * @param {string} price 코인양
   * @param {string} owner approve한 지갑주소
*/
const register = async (
    pk: string,
    price: string,
    owner: string,
) => {
    const postData = {
        pk: pk,
        price: price,
        owner: owner,
    };
    const res = await DefaultClient.post(`/api/nft/market`, postData);

    return res.data;
}



/**
  NFT approve한 주인이 변경되어 approve 권한이 없을 떄
   * @param {number} pk pk
*/
const reset = async (
    pk: number,
) => {
    const res = await DefaultClient.post(`/api/nft/${pk}`);
    return res.data;
}


/**
  NFT 이미지 다운로드
   * @param {string} contract 컨트렉트
   * @param {string} tokenId 토큰 ID
   * @param {string} dataCode NFT 메타데이터에있는
*/
const download = async (
    contract: string,
    tokenId: string,
    dataCode: string,
) => {
    const getExtensionFromContentType = (contentType: string): string => {
        switch (contentType) {
          case 'image/jpeg':
            return 'jpg';
          case 'image/png':
            return 'png';
          case 'image/gif':
            return 'gif';
          case 'image/webp':
            return 'webp';
          case 'image/svg+xml':
            return 'svg';
          default:
            return 'png'; // 기본값으로 png를 사용
        }
      };

      
      const response = await axios.get(dataCode, { responseType: 'blob' });
      const contentType = response.headers['content-type'];
      const extension = getExtensionFromContentType(contentType);


      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${contract} #${tokenId}.${extension}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // 링크를 사용 후 제거

}


/**
  구매 내역 가져오기
   * @param {object} params - 파라미터 객체
   * @param {number} params.page 현 페이지
   * @param {number} params.size 가져올 갯수
   * @param {string?} params.category 카테고리
   * @param {AddressType} params.address 지갑 주소
*/
const getPurchased = async (
    {page, size, query, address}:{page: number, size: number, query?: any, address: AddressType}
) => {
    const postData = {
        page: page,
        count: size,
        query: JSON.stringify(query ?? {}).replaceAll('\"true\"','true'),
    };
    const res = await DefaultClient.post(`/api/nft/history/buy`, postData, {
        headers: {
            wallet: address
        }
    });

    return res.data;
}


/**
  판매 내역 가져오기
   * @param {object} params - 파라미터 객체
   * @param {number} params.page 현 페이지
   * @param {number} params.size 가져올 갯수
   * @param {string?} params.category 카테고리
   * @param {AddressType} params.address 지갑 주소
*/
const getSold = async (
    {page, size, query, address}:{page: number, size: number, query?: any, address: AddressType}
) => {
    const postData = {
        page: page,
        count: size,
        query: JSON.stringify(query ?? {}).replaceAll('\"true\"','true'),
    };
    const res = await DefaultClient.post(`/api/nft/history/sell`, postData,{
        headers: {
            wallet: address
        }
    });

    return res.data;
}



/**
  제안 목록 확인
   * @param {string} pk nft pk
*/
const getOfferList = async (
    {pk}:{pk: string}
) => {
    // const postData = {
    //     page: page,
    //     size: size,
    //     query: JSON.stringify(query ?? {}).replaceAll('\"true\"','true'),
    // };
    const res = await DefaultClient.get(`/api/offer/${pk}`);
    return res.data;
}


/**
  제안 승인
   * @param {number} pk offer pk
*/
const confirmOffer = async (
    {pk}:{pk: number}
) => {
    const postData = {
        offerPk: pk
    }
    const res = await DefaultClient.post(`/api/offer/confirm`, postData);
    return res.data;
}

/**
  제안 거부
   * @param {number} pk offer pk
*/
const denyOffer = async (
    {pk}:{pk: number}
) => {
    const postData = {
        offerPk: pk
    }
    const res = await DefaultClient.post(`/api/offer/cancelOffer`, postData);
    return res.data;
}


/**
  제안
     * @param {object} params - 파라미터 객체
  * @param {string} params.pk 제안할 nft pk
   * @param {string} params.amount 가격
   * @param {string} params.network 제안한 network
   * @param {string} params.ownerWallet 제안한 사람의 지갑
*/
const offer = async (
    {pk, amount, network, ownerWallet}:{pk: number, amount: string, network: string, ownerWallet: string}
) => {
    const postData = {
        nftPk: pk,
        amount: amount,
        network: network,
        ownerWallet: ownerWallet
    }
    const res = await DefaultClient.post(`/api/offer/postOffer`, postData);
    return res.data;
}

/**
  수수료 가져오기
   * @param {object} params - 파라미터 객체
   * @param {number} params.page 현 페이지
*/
const getFee = async () => {
    const res = await DefaultClient.get('/api/nft/fee');
    return res.data;
}

/**
 * NFT 이미지 전송하기
 */
// const sendNftImage = async (
//   tokenId : string,
//   contractAddress : string,
//   network : string,
//   targetUrl : string
// ) => {
//     const Data = {
//         tokenId: tokenId,
//         contractAddress,
//         network,
//         targetUrl,
//     };
//     const res = await DefaultClient.post(`/api/offer/sendNftImage`, Data);
//     return res.data;
// }

const nft = {
    getList,
    getCategoryInfo,
    setLike, getLike,
    getInfo,
    register, reset,
    trade, getTx,
    download,
    exchangeToDollar,
    getPurchased, getSold,
    offer, getOfferList, confirmOffer, denyOffer,
};

const coin = {
//     exchange
}

const setting = {
    getFee
}

const userApi = {
    nft,
    coin,
    setting
}

export default userApi;