import { Route, Routes } from "react-router-dom";
import { RegisterSearchListPage } from "../../view/Admin/Register/RegisterSearchListPage";
import { RegisterSearchPage } from "../../view/Admin/Register/RegisterSearchPage";
import NotFoundPage from "../../view/NotFound/NotFoundPage";
import AdminLoginPage from "../../view/Admin/Login/AdminLoginPage";
import { AdminMainPage } from "../../view/Admin/Main/AdminMainPage";
import { ContractMeta } from "../../view/Admin/Contract/ContractMeta";
import { ManagementPage } from "../../view/Admin/Management/ManagementPage";
import {RegisterAllPage} from "../../view/Admin/Register/RegisterAllPage";

const AdminRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminLoginPage />} />
      <Route path="/setting" element={<AdminMainPage topMenu={"설정"}/>} />
      <Route path="/contract" element={<ContractMeta topMenu={"Contract 등록"}/>} />
      <Route path="/register/search" element={<RegisterSearchPage topMenu={"NFT 등록"}/>} />
      <Route path="/register/all" element={<RegisterAllPage topMenu={"NFT 일괄 등록"} />} />
      <Route path="/register/search/list" element={<RegisterSearchListPage topMenu={"NFT 등록"}/>} />
      <Route path="/management" element={<ManagementPage topMenu={"NFT 관리"}/>} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AdminRoute;
