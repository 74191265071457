import React from "react";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { AdminLayout } from "../../../components/Layout/AdminLayout";
import { chainListArray } from "../../../controller/chainId";
import { ContractMetaPageProps } from "../../../interface/view/interface";
import { alertModalState, loadingModalState } from "../../../modules/Modal";
import theme from "../../../theme";
import adminApi from "../../../api/adminApi";
import { useSelector } from "react-redux";
import { RootReducerState } from "../../../redux/RootReducer";

interface ICategory {
  label: string;
  value: string;
}

export const categoryList: ICategory[] = [
  { label: "Digital Art", value: "digital-art" },
  { label: "Sports", value: "sports" },
  { label: "Collectable", value: "collectable" },
  { label: "Game", value: "game" },
];

export const RegisterAllPage = (props: ContractMetaPageProps) => {
  const alertModal = useSetRecoilState(alertModalState);
  const user = useSelector((state: RootReducerState) => state.user);

  const [contract, setContract] = React.useState<string>('');
  const [category, setCategory] = React.useState<string>(categoryList[0].value);
  const loadingModal = useSetRecoilState(loadingModalState);
  const resetLoadingModal = useResetRecoilState(loadingModalState);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (contract === '') {
      alertModal({
        isModal: true,
        title: '오류',
        content: "컨트렉트를 입력해주세요.",
      });
      return;
    };

    loadingModal({
      isModal: true,
      isButton: false,
      type: "default",
      subText: `${contract}\nNFT를 등록하는 중입니다.`
    });

    const res = await adminApi.registerNFTAll(user.token, contract, category);

    resetLoadingModal()
    if(res.status !== 200){
      alertModal({
        isModal: true,
        title: '오류',
        content: "현 상황이 지속된다면,\n개발자에게 문의해주세요.",
      });
    }
    alertModal({
      isModal: true,
      title: '성공',
      content: "NFT일괄 등록이 완료되었습니다.",
      type: 'check',
      onClick() {
        setContract('');
        setCategory(categoryList[0].value);
      },
    });
  }

  const switchNetwork = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newNetwork = e.target.value;
  }

  return (
    <AdminLayout {...props}>
      <FormContainer>
        <Form onSubmit={onSubmit}>
          <FormRow>
            <Label>스마트 컨트랙트</Label>
            <Input type="text" value={contract} onChange={e => setContract(e.target.value)} />
          </FormRow>
          <FormRow>
            <Label>카테고리</Label>
            <Select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {categoryList.map((cat) => (
                <option key={cat.value} value={cat.value}>{cat.label}</option>
              ))}
            </Select>
          </FormRow>
          <ButtonRow>
            <SubmitButton type="submit">NFT 일괄 등록하기</SubmitButton>
          </ButtonRow>
        </Form>
      </FormContainer>
    </AdminLayout>
  );
};

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const Title = styled.label`
    font-size: 20px;
    text-align: center;
    color: ${theme.colors.white};
    margin-bottom: 20px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme.colors.blueGray1};
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
`;

const FormRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
`;

const Label = styled.label`
    font-size: 14px;
    text-align: start;
    width: 150px;
`;

const Input = styled.input`
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const Select = styled.select`
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
`;

const ButtonRow = styled(FormRow)`
    justify-content: center;
`;

const SubmitButton = styled.button`
    padding: 12px 24px;
    background-color: ${theme.colors.secondary};
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background-color: ${theme.colors.side};
    }
`;