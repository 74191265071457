import React from "react";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { AdminLayout } from "../../../components/Layout/AdminLayout";
import { chainListArray } from "../../../controller/chainId";
import { ContractMetaPageProps } from "../../../interface/view/interface";
import { alertModalState, loadingModalState } from "../../../modules/Modal";
import theme from "../../../theme";
import adminApi from "../../../api/adminApi";
import { useSelector } from "react-redux";
import { RootReducerState } from "../../../redux/RootReducer";

const standardOptions: Record<string, {value: string, label: string}[]> = {
  "ethereum": [
    { value: "ERC-721", label: "ERC-721" },
    { value: "ERC-1155", label: "ERC-1155" }
  ],
  "binance": [
    { value: "BEP-721", label: "BEP-721" },
    { value: "BEP-1155", label: "BEP-1155" }
  ],
  "polygon": [
    { value: "ERC-721", label: "ERC-721" },
    { value: "ERC-1155", label: "ERC-1155" }
  ],
  "klaytn": [
    { value: "KIP-17", label: "KIP-17" },
    // { value: "KIP-37", label: "KIP-37" }
  ]
};

export const ContractMeta = (props: ContractMetaPageProps) => {
  
  const alertModal = useSetRecoilState(alertModalState);
  const user = useSelector((state: RootReducerState) => state.user);

  const [network, setNetWork] = React.useState<string>(chainListArray[0].network);
  const [standard, setStandard] = React.useState<string>(standardOptions[network][0].value);
  const [contract, setContract] = React.useState<string>('');
  const [startBlock, setStartBlock] = React.useState<string>('0');
  const loadingModal = useSetRecoilState(loadingModalState);
  const resetLoadingModal = useResetRecoilState(loadingModalState);

  

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (standard === '') {
      alertModal({
        isModal: true,
        title: '오류',
        content: "표준을 선택해주세요.",
      });
      return;
    }
    if (contract === '') {
      alertModal({
        isModal: true,
        title: '오류',
        content: "컨트렉트를 입력해주세요.",
      });
      return;
    };
   
    if (standard === 'KIP-37') {
      alertModal({
        isModal: true,
        title: '제한',
        content: "해당 표준은 업데이트가 필요합니다.",
      });
      return;
    };
   

    loadingModal({
      isModal: true,
      isButton: false,
      type: "default",
      subText: `${contract}\n메타데이터를 가져오는 중입니다.`
    });

    const res = await adminApi.getContractMeta(user.token, network, contract, standard, startBlock);

    resetLoadingModal()
    if (res.message === "fail") {
      alertModal({
        isModal: true,
        title: '오류',
        content: "현 상황이 지속된다면,\n개발자에게 문의해주세요.",
      });
    } else {
      alertModal({
        isModal: true,
        title: '성공',
        content: "메타데이터를 가져왔습니다",
        type: 'check',
        onClick() {
          setNetWork(chainListArray[0].value);
          setContract('');
          // setStartBlock('');
        },
      });
    }
  }

  const switchNetwork = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newNetwork = e.target.value;
    setNetWork(newNetwork);
    setStandard(standardOptions[newNetwork][0].value);
    // let set;
    // for(let i = 0; i < standardOptions[newNetwork].length; i ++){
    //   if(standard === standardOptions[newNetwork][0].value){
    //     set = i;
    //   }
    // }
    // if(set === undefined){
    //   setStandard('')
    // }else{
    //   setStandard(standardOptions[newNetwork][set].value)
    // }
  }

  return (
    <AdminLayout {...props}>
      <FormContainer>
        <Title>* 현 작업은, '컨트랙트 최초 블럭'부터 스캔합니다.</Title>
        <Form onSubmit={onSubmit}>
          <FormRow>
            <Label>네트워크</Label>
            <Select value={network} onChange={switchNetwork}>
              {chainListArray.map((chain) => {
                return (
                  <option value={chain.network} key={chain.network}>{chain.ko}</option>
                )
              })}
            </Select>
          </FormRow>
          <FormRow>
            <Label>표준</Label>
            <Select value={standard} onChange={(e) => setStandard(e.target.value)}>
            {standardOptions[network]?.map((option) => {
                return (
                  <option value={option.value} key={option.value}>{option.label}</option>
                )
              })}
            </Select>
          </FormRow>
          <FormRow>
            <Label>스마트 컨트랙트</Label>
            <Input type="text" value={contract} onChange={e => setContract(e.target.value)} />
          </FormRow>
          {/* <FormRow>
            <Label>시작블록</Label>
            <Input type="number" value={startBlock} onChange={e => setStartBlock(e.target.value)} />
          </FormRow> */}
          <ButtonRow>
            <SubmitButton type="submit">메타데이터 가져오기</SubmitButton>
          </ButtonRow>
        </Form>
      </FormContainer>
    </AdminLayout>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Title = styled.label`
  font-size: 20px;
  text-align: center;
  color: ${theme.colors.white};
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.colors.blueGray1};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 14px;
  text-align: start;
  width: 150px;
`;

const Input = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Select = styled.select`
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ButtonRow = styled(FormRow)`
  justify-content: center;
`;

const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: ${theme.colors.secondary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: ${theme.colors.side};
  }
`;
